<template>
  <AddSubsidiaryAssetsToSiteOverlayStyled>
    <SubsidiariesListStyled>
      <h4>Select Subsidiaries</h4>
      <Multiselect
        class="multiselect"
        :options="subsidiaries"
        :value="selectedSubsidiaries"
        :multiple="true"
        trackBy="id"
        label="name"
        :placeholder="'select'"
        :internalSearch="true"
        :close-on-select="false"
        @select="addSelectedSubsidiaries"
        @remove="removeSelectedSubsidiaries"
      />
    </SubsidiariesListStyled>
    <SubsidiaryAssetsStyled>
      <div class="title">
        <h4>Assets</h4>
        <Badge :label="selectedAssets && selectedAssets.length + ''" />
      </div>
      <div class="asset-list">
        <ul v-if="selectedAssets && selectedAssets.length">
          <li class="asset-item" v-for="(asset, index) in selectedAssets" :key="index">
            {{ asset.name }}
          </li>
        </ul>

        <p v-else>Oops you have not added any Assets yet!... Please select a subsidiary!</p>
      </div>
    </SubsidiaryAssetsStyled>
    <FooterStyled>
      <ButtonsGroup>
        <ButtonStyled :disabled="selectedSubsidiaries.length < 1" @click="addAssetsToSite">
          {{ $tc('actions.add') }}
        </ButtonStyled>
        <ButtonStyled color="grey" @click="$root.$emit('closeOverlay')">
          {{ $tc('actions.cancel') }}
        </ButtonStyled>
      </ButtonsGroup>
    </FooterStyled>
  </AddSubsidiaryAssetsToSiteOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { FlashMessages } from '@common/singletons'
import { ButtonsGroup, ButtonStyled } from './styles'
import { Badge } from '@common/components'

import SUBSIDIARIES_QUERY from '#/graphql/operations/subsidiaries/subsidiariesBasic.gql'
import SUBSIDIARIES_WITH_ASSETS_QUERY from '#/graphql/operations/subsidiaries/subsidiariesWithAssetsQuery.gql'
import ADD_MANY_ASSETS_TO_SITE from '#/graphql/sites/addManyAssetsToSite.gql'

const AddSubsidiaryAssetsToSiteOverlayStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 3rem;
  grid-template-areas:
    'selectSubsidiaries selectedAssets'
    'footer footer';
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: ${p => chroma(p.theme.colors.black).css()};
  width: 65%;
  height: 80%;
  margin: 2rem;
  border: 1px solid ${p => p.theme.colors.archonBlue};
`
const SubsidiariesListStyled = styled('div')`
  grid-area: selectSubsidiaries;
  height: 100%;
  > h4 {
    margin: 0;
  }
  padding: 1rem;

  .multiselect {
    margin-top: 10px;
    .multiselect__content-wrapper {
      max-height: 350px !important;
    }
  }
`

const SubsidiaryAssetsStyled = styled('div')`
  grid-area: selectedAssets;
  height: 100%;
  overflow: hidden;
  padding: 1rem;
  .title {
    display: flex;
    position: sticky;
    top: 0;
    gap: 1rem;
    align-items: center;
    > h4 {
      margin: 0;
    }
  }

  background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  .asset-list {
    height: 100%;
    overflow: auto;
    > p {
      text-align: center;
    }
  }
  .asset-item {
    border-radius: 4px;
    margin-bottom: 10px;
  }
`
const FooterStyled = styled('div')`
  grid-area: footer;
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    AddSubsidiaryAssetsToSiteOverlayStyled,
    SubsidiariesListStyled,
    SubsidiaryAssetsStyled,
    FooterStyled,
    ButtonsGroup,
    ButtonStyled,
    Multiselect,
    Badge,
  },
  data() {
    return {
      subsidiaries: [],
      subsidiariesWithAssets: [],
      selectedSubsidiaries: [],
    }
  },
  computed: {
    selectedAssets() {
      if (this.subsidiariesWithAssets.length < 1) {
        return []
      }
      const result = this.selectedSubsidiaries
        .map(sub => {
          const match = this.subsidiariesWithAssets.find(subWithAssets => sub.name === subWithAssets.name)
          return match ? match.assets : []
        })
        .flat()
      return result
    },
  },
  methods: {
    addSelectedSubsidiaries(subsidiary) {
      this.selectedSubsidiaries.push(subsidiary)
    },
    removeSelectedSubsidiaries(subsidiary) {
      this.selectedSubsidiaries = this.selectedSubsidiaries.filter(value => value.id !== subsidiary.id)
    },
    async addAssetsToSite() {
      if (this.selectedAssets.length < 1) {
        return
      }
      const uniqueIds = new Set()
      const finalAssets = []

      const combinedAssets = [...this.selectedAssets, ...this.args?.site?.assets]

      for (const asset of combinedAssets) {
        if (!uniqueIds.has(asset.id)) {
          finalAssets.push({ assetId: asset.id, assetType: asset.__typename })
          uniqueIds.add(asset.id)
        }
      }

      try {
        this.$apollo
          .mutate({
            mutation: ADD_MANY_ASSETS_TO_SITE,
            variables: {
              where: {
                id: this.$route.params.id,
              },
              data: {
                assignedAssets: {
                  create: finalAssets,
                },
              },
            },
          })
          .then(async () => {
            FlashMessages.$emit('success', `${finalAssets.length} new assets are added to ${this.args?.site.alias}`, {
              timeout: 3000,
            })
            this.$root.$emit('closeOverlay')
          })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    subsidiaries: {
      query: SUBSIDIARIES_QUERY,
    },
    subsidiariesWithAssets: {
      query: SUBSIDIARIES_WITH_ASSETS_QUERY,
      variables() {
        return {
          where: {
            id_in: this.selectedSubsidiaries.map(subs => subs.id),
          },
        }
      },
      skip() {
        return this.selectedSubsidiaries.length < 1
      },
      update: ({ subsidiaries }) => {
        return subsidiaries
      },
    },
  },
}
</script>
