var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AddSubsidiaryAssetsToSiteOverlayStyled', [_c('SubsidiariesListStyled', [_c('h4', [_vm._v("Select Subsidiaries")]), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "options": _vm.subsidiaries,
      "value": _vm.selectedSubsidiaries,
      "multiple": true,
      "trackBy": "id",
      "label": "name",
      "placeholder": 'select',
      "internalSearch": true,
      "close-on-select": false
    },
    on: {
      "select": _vm.addSelectedSubsidiaries,
      "remove": _vm.removeSelectedSubsidiaries
    }
  })], 1), _c('SubsidiaryAssetsStyled', [_c('div', {
    staticClass: "title"
  }, [_c('h4', [_vm._v("Assets")]), _c('Badge', {
    attrs: {
      "label": _vm.selectedAssets && _vm.selectedAssets.length + ''
    }
  })], 1), _c('div', {
    staticClass: "asset-list"
  }, [_vm.selectedAssets && _vm.selectedAssets.length ? _c('ul', _vm._l(_vm.selectedAssets, function (asset, index) {
    return _c('li', {
      key: index,
      staticClass: "asset-item"
    }, [_vm._v(" " + _vm._s(asset.name) + " ")]);
  }), 0) : _c('p', [_vm._v("Oops you have not added any Assets yet!... Please select a subsidiary!")])])]), _c('FooterStyled', [_c('ButtonsGroup', [_c('ButtonStyled', {
    attrs: {
      "disabled": _vm.selectedSubsidiaries.length < 1
    },
    on: {
      "click": _vm.addAssetsToSite
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.add')) + " ")]), _c('ButtonStyled', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('closeOverlay');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.cancel')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }